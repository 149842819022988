export const PERMISSIONS_DICTIONARY = {
  applications: {
    name: 'Applications',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete' },
  },
  roles: {
    name: 'Roles',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete' },
  },
  entities: {
    name: 'Entities',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete' },
  },
  permissions: {
    name: 'Permissions',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete', assign: 'Assign' },
  },
};
